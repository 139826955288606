<template>
  <div class="pageContol largeScreen">
    <div style="width:100%">
      <header>
        <div class="header">
          <div
            style="width:40%;padding-left:5rem;margin-bottom: 10px;margin-top: 10px;"
          >
            <h3>北京晟融信息技术有限公司</h3>
            <h4>河北秦安安全科技股份有限公司</h4>
          </div>
          <div
            style="width:60%;display: flex;flex-direction: column;align-items: center;margin-bottom: 1rem;"
          >
            <h2 style="font-size:26px">班级明细</h2>
          </div>
          <div style="width:40%;margin-bottom: 10px;" class="weekBox">
            <h2 style="font-size:32px">{{ nowTime }}</h2>
            <div style="padding-right:0.675rem">
              <p>{{ nowWeek }}</p>
              <p>{{ nowDate }}</p>
            </div>
          </div>
        </div>
      </header>
      <div class="framePage" style="background:#03051b;height:91%">
        <section class="sectionBoxs framePage-body">
          <div class="operationControl" style="margin:0 0.657rem;">
            <div class="searchbox" style="margin-bottom:18px">
              <div title="查询条件" class="searchboxItem ci-full">
                <span class="itemLabel" style="color:#FFFFFF;font-weight: bold;"
                  >查询条件</span
                >
              </div>
              <div title="班级名称" class="searchboxItem ci-full">
                <span class="itemLabel" style="color:#FFFFFF">班级名称:</span>
                <el-input
                  v-model="projectName"
                  type="text"
                  size="small"
                  placeholder="请输入班级名称"
                  clearable
                />
              </div>
              <el-button
                class="bgc-large"
                style="margin:0 10px"
                @click="getDataList()"
                >查询</el-button
              >
            </div>
            <div class="searchbox" style="margin-bottom:18px">
              <el-button
                class="bgc-large"
                style="margin:0 10px"
                @click="back()"
                >返回</el-button
              >
            </div>
          </div>
          <div class="framePage-scroll">
            <div
              style="display: flex;flex-direction: column;flex:1;height:100%"
            >
              <ul style="display:flex;" class="ulTitle">
                <li>
                  <span  style="max-width:60px">序号</span>
                  <span >班级名称</span>
                  <span style="max-width:200px">岗位类型</span>
                  <span style="max-width:200px">行业类型</span>
                  <span style="max-width:200px">培训等级</span>
                  <span style="max-width:80px">要求学时</span>
                  <span style="max-width:160px">开始时间</span>
                  <span style="max-width:160px">结束时间</span>
                  <span style="max-width:120px">培训状态</span>
                  <span style="max-width:120px">学员人数</span>
                  <span style="max-width:120px">培训进度</span>
                </li>
              </ul>
              <vue-seamless-scroll
                :data="tableDatas"
                class="seamless-warp"
                :class-option="classOption"
              >
                <ul
                  style="display:flex;flex-direction: column;"
                  class="ulTitle ulcontent"
                >
                  <li
                    v-for="(item, index) in tableDatas"
                    :class="{ single: index % 2 == 0, double: index % 2 != 0 }"
                    :key="index"
                  >
                    <span style="max-width:60px">{{ index + 1 }}</span>
                    <span>{{ item.projectName }}</span>
                    <span style="max-width:200px">{{ item.postName }}</span>
                    <span style="max-width:200px">{{ item.industryNamePath }}</span>
                    <span style="max-width:200px">{{ item.trainLevelName }}</span>
                    <span style="max-width:80px">{{ item.lessonNum }}</span>
                    <span style="max-width:160px">{{ item.startDate | momentDate }}</span>
                    <span style="max-width:160px">{{ item.endDate | momentDate }}</span>
                    <span style="max-width:120px">{{ $setDictionary("PROJECTSTATE", item.projectState)}}</span>
                    <span style="max-width:120px">{{ item.totalPeopleNum}}</span>
                    <span style="max-width:120px">{{ item.percentage + '%' }}</span>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
        </section>
    
      </div>
    </div>
  </div>
</template>
<script>
// import Empty from "@/components/Empty.vue";
// import PageNum from "@/components/PageNum.vue";
// import countTo from "vue-count-to";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
import scroll from "vue-seamless-scroll";
export default {
  name: "OneEnterpriseOneFile",
  components: {
    // countTo,
    // Empty,
    // PageNum,
  },
  mixins: [List],
  data() {
    return {
        dialogDownload:false,
      dialog1: false,
      ruleForm: {},
      nowDate: null, //存放年月日变量
      nowTime: null, //存放时分秒变量
      timer: "", //定义一个定时器的变量
      currentTime: new Date(), // 获取当前时间
      tableDatas: [],
      projectName: "",

    };
  },
  created() {
    this.timer = setInterval(this.getTime, 1000);
    this.getDataList()
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 15, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 56, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        singleHeight: 330,
        waitTime: 5000,
      };
    },
  },
  mounted() {
    this.getDataList();
  },
  watch: {
    userId: function() {
      this.superUserSelect();
    },
  },
  methods: {
    getDataList() {
      let paramsa = {
          currentYear: this.$route.query.currentYear || false,
          training: this.$route.query.traing || false,
      };
      if (this.projectName) {
        paramsa.projectName = this.projectName;
      }
      if (this.$route.query.parmar) {
        paramsa.postId = this.$route.query.parmar.params;
      }
      
      this.$post("/biz/large/screen/display/queryProjectDetail", paramsa).then((ret) => {
        console.log(ret);
        this.tableDatas = ret.data.list;
      });
    },
    //控制行颜色
    tableRowClassName({ row, rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return "double"; //对应的类
      } else {
        return "single"; //对应的类
      }
    },
    getTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      const wk = date.getDay();
      this.month = check(month);
      this.day = check(day);
      this.hour = check(hour);
      this.minute = check(minute);
      this.second = check(second);
      function check(i) {
        const num = i < 10 ? "0" + i : i;
        return num;
      }
      let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let week = weeks[wk];
      this.nowDate = year + "年" + this.month + "月" + this.day + "日";
      this.nowTime = this.hour + ":" + this.minute + ":" + this.second;
      this.nowWeek = week;
    },
    back() {
        this.$router.back();
    },
    clickRow(row, column, event) {
      this.studentInfo(row.userId);
    },
    studentInfo(userId) {
      this.dialog1 = true;
      this.$post("/largescreen/ls-config/studentDetail", { userId })
        .then((ret) => {
          console.log(ret);
          this.ruleForm = {
            ...ret.data,
          };
        })
        .catch((err) => {
          return;
        });
    },
    see(item) {
      let parmar = {
        projectId: item.projectId,
        userId:  item.userId,
      };
      this.$post("/cert/downloadTrainDocument", parmar).then((res) => {
        if (res.status == 0) {
          this.dialogDownload = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-content");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.bgc-large {
  background: rgba(8, 9, 36, 0);
  box-shadow: 0px 0px 88px 7px rgba(16, 108, 222, 0.22),
    0px 0px 11px 3px rgba(0, 114, 255, 0.8);
  border-radius: 10px;
  color: #fff;
  border: none;
}
header {
  height: 9%;
  color: #fff;
}
.header {
  display: flex;
  align-items: center;
  height: 100%;
  .weekBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h2 {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }
}
.sectionBoxs {
  height: 100%;
  background: transparent;
}

.el-form-item {
  width: 30%;
  margin-bottom: 0;
  height: 1.5rem;
}

.pageContol {
  background-image: url("../../assets/BG.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.studentBox {
  display: flex;
  padding: 2rem 0;
}
.el-form-item {
  width: 30%;
  margin-bottom: 0;
  height: 1.5rem;
}
.seamless-warp {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 0 1rem 0;
}
.ulTitle {
  font-size: 1rem;
  // padding: 0 2.35rem;
  li {
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #ffffff;
    
    cursor: pointer;
    span {
      text-align: left;
      flex: 1;
      padding: 0.5rem 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 100%;
    }
  }
  .projectNum {
    span {
      color: #52ffff;
    }
  }
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
<style lang="less">
.largeScreen {


// tr {
//   pointer-events: none;
// }
.el-table tbody tr:hover > td {
  background-color: transparent !important;
}
.el-table,
.el-table__expanded-cell {
  background: transparent;
}
.el-table .cell {
  color: #cecece;
}
.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/*更改表格颜色*/
.double {
  background: #141e38 !important;
}
.single {
  background: #03051b !important;
}
.el-dialog {
  background: transparent;
}
.el-dialog__header {
  display: none;
}
.el-dialog__body {
  background-image: url("../../assets/dialogbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0;
}
.studentspan {
  width: 4rem;
}
}
</style>
